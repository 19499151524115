<template>
  <section class="custom-field">
    <section class="custom-field__texts">
      <h2 class="custom-field__texts__label">
        {{ label }}
      </h2>
      <p class="custom-field__texts__description">
        {{ description }}
      </p>
    </section>
    <slot />
  </section>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
      default: () => "Nome do campo",
    },
    description: {
      type: String,
      required: false,
      default: () => "",
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-field {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #ededf0;
  padding-bottom: 20px;

  &__texts {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__label {
      color: #333;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 600;
    }
    &__description {
      color: #81858e;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 400;
      line-height: 150%; /* 19.5px */
    }
  }
}
</style>