<template>
  <section class="create_product-content border-none">
    <custom-header
      :title="$t('views.seller.create_product.views.mentoring.title')"
      :subtitle="$t('views.seller.create_product.views.mentoring.subtitle')"
      :show-button="false"
    />
    <section class="inline-fields">
      <b-form-group
        :label="$t('views.seller.create_product.views.mentoring.period')"
        label-for="mentoring_period"
      >
        <b-form-input
          id="mentoring_period"
          name="mentoring_period"
          v-model="mentoring_period"
          type="text"
          v-validate="'required'"
          :class="{ heaven: isHeaven }"
          :placeholder="
            $t('views.seller.create_product.views.mentoring.period_placeholder')
          "
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('mentoring_period')">
          {{ $t("views.seller.create_product.inputs.mentoring.period_error") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        :label="$t('views.seller.create_product.views.mentoring.duration')"
        label-for="mentoring_duration"
      >
        <b-form-input
          id="mentoring_duration"
          name="mentoring_duration"
          v-model="mentoring_duration"
          type="text"
          v-validate="'required'"
          :class="{ heaven: isHeaven }"
          :placeholder="
            $t(
              'views.seller.create_product.views.mentoring.duration_placeholder'
            )
          "
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('mentoring_duration')">
          {{
            $t("views.seller.create_product.inputs.mentoring.duration_error")
          }}
        </b-form-invalid-feedback>
      </b-form-group>
    </section>
  </section>
</template>
<script>
import CustomHeader from "./shared/CustomHeader.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CustomHeader,
  },
  watch: {
    callValidateFields() {
      this.validateFields();
    },
  },
  computed: {
    ...mapGetters(["product", "isLoading", "categories", "callValidateFields"]),
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    mentoring_period: {
      get() {
        return this.$store.state.product.product.mentoring_period;
      },
      set(mentoring_period) {
        this.$store.commit("setProduct", { ...this.product, mentoring_period });
      },
    },
    mentoring_duration: {
      get() {
        return this.$store.state.product.product.mentoring_duration;
      },
      set(mentoring_duration) {
        this.$store.commit("setProduct", {
          ...this.product,
          mentoring_duration,
        });
      },
    },
  },
  methods: {
    validateFields() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;
        this.$emit("finishValidation");
      });
    },
  },
};
</script>
  