<template>
  <cobranca
    v-if="!!product"
    :isMobile="isMobile"
    :co_sellers_list="coSellers"
    :id_product="productId"
    :warranty="product.warranty"
    :methods="methods"
    :option_fixed_installments="option_fixed_installments"
    :option_max_installments="option_max_installments"
    :productAmount="productAmount"
    :product="product"
    :force-edit="triggerClickFromSaleExpress"
  />
</template>
<script>
// Core
import { mapGetters } from "vuex";

// Components
import CustomHeader from "./shared/CustomHeader.vue";
import Cobranca from "@/views/Seller/Cobranca/Cobranca";

// Mixins
import Money from "@/mixins/money";

// Services
import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

export default {
  components: {
    CustomHeader,
    Cobranca,
  },
  data() {
    return {
      client: {
        width: 0,
      },
      option_fixed_installments: [
        { value: null, text: this.$t("views.seller.edit_product.text_2292") },
        { value: 12, text: this.$t("views.seller.edit_product.text_2293") },
        { value: 11, text: this.$t("views.seller.edit_product.text_2294") },
        { value: 10, text: this.$t("views.seller.edit_product.text_2295") },
        { value: 9, text: this.$t("views.seller.edit_product.text_2296") },
        { value: 8, text: this.$t("views.seller.edit_product.text_2297") },
        { value: 7, text: this.$t("views.seller.edit_product.text_2298") },
        { value: 6, text: this.$t("views.seller.edit_product.text_2299") },
        { value: 5, text: this.$t("views.seller.edit_product.text_2300") },
        { value: 4, text: this.$t("views.seller.edit_product.text_2301") },
        { value: 3, text: this.$t("views.seller.edit_product.text_2302") },
        { value: 2, text: this.$t("views.seller.edit_product.text_2303") },
      ],
      option_pre_selected_installment: [
        {
          value: null,
          text: this.$t("views.seller.edit_product.text_2304"),
          disabled: true,
        },
        { value: 12, text: this.$t("views.seller.edit_product.text_2305") },
        { value: 11, text: this.$t("views.seller.edit_product.text_2306") },
        { value: 10, text: this.$t("views.seller.edit_product.text_2307") },
        { value: 9, text: this.$t("views.seller.edit_product.text_2308") },
        { value: 8, text: this.$t("views.seller.edit_product.text_2309") },
        { value: 7, text: this.$t("views.seller.edit_product.text_2310") },
        { value: 6, text: this.$t("views.seller.edit_product.text_2311") },
        { value: 5, text: this.$t("views.seller.edit_product.text_2312") },
        { value: 4, text: this.$t("views.seller.edit_product.text_2313") },
        { value: 3, text: this.$t("views.seller.edit_product.text_2314") },
        { value: 2, text: this.$t("views.seller.edit_product.text_2315") },
        { value: 1, text: this.$t("views.seller.edit_product.text_2316") },
        { value: 0, text: this.$t("views.seller.edit_product.text_2290") },
      ],
      option_max_installments: [
        {
          value: null,
          text: this.$t("views.seller.edit_product.text_2289"),
          disabled: true,
        },
        { value: 12, text: this.$t("views.seller.edit_product.text_2317") },
        { value: 11, text: this.$t("views.seller.edit_product.text_2318") },
        { value: 10, text: this.$t("views.seller.edit_product.text_2319") },
        { value: 9, text: this.$t("views.seller.edit_product.text_2320") },
        { value: 8, text: this.$t("views.seller.edit_product.text_2321") },
        { value: 7, text: this.$t("views.seller.edit_product.text_2322") },
        { value: 6, text: this.$t("views.seller.edit_product.text_2323") },
        { value: 5, text: this.$t("views.seller.edit_product.text_2324") },
        { value: 4, text: this.$t("views.seller.edit_product.text_2325") },
        { value: 3, text: this.$t("views.seller.edit_product.text_2326") },
        { value: 2, text: this.$t("views.seller.edit_product.text_2327") },
        { value: 1, text: this.$t("views.seller.edit_product.text_2290") },
      ],
      option_max_comission: [
        {
          value: 100,
          text: this.$t("views.seller.edit_product.text_2328"),
          selected: true,
        },
        { value: 12, text: this.$t("views.seller.edit_product.text_2329") },
        { value: 11, text: this.$t("views.seller.edit_product.text_2330") },
        { value: 10, text: this.$t("views.seller.edit_product.text_2331") },
        { value: 9, text: this.$t("views.seller.edit_product.text_2332") },
        { value: 8, text: this.$t("views.seller.edit_product.text_2333") },
        { value: 7, text: this.$t("views.seller.edit_product.text_2334") },
        { value: 6, text: this.$t("views.seller.edit_product.text_2335") },
        { value: 5, text: this.$t("views.seller.edit_product.text_2336") },
        { value: 4, text: this.$t("views.seller.edit_product.text_2337") },
        { value: 3, text: this.$t("views.seller.edit_product.text_2338") },
        { value: 2, text: this.$t("views.seller.edit_product.text_2339") },
        { value: 1, text: this.$t("views.seller.edit_product.text_2340") },
      ],
      triggerClickFromSaleExpress: false
    };
  },
  computed: {
    ...mapGetters([
      "product",
      "productId",
      "isLoading",
      "coSellers",
      "productAmount",
      "productMethods"
    ]),
    /**
     * @return {boolean} - If user is heaven or greenn;
     */
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    isMobile() {
      return this.client.width <= 768;
    },
    methods() {
      return this.productMethods || [];
    },
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    verifySaleExpressFlow() {
      const from = this.$route.query.from;
      if (from === 'sale_express') {
        this.triggerClickFromSaleExpress = true;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.verifySaleExpressFlow();
  },
  mixins: [Money],
};
</script>
