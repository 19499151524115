<template>
  <b-row class="mt-4">
    <b-col cols="12" class="mb-4">
      <custom-header
        :title="$t('views.seller.create_product.views.event.event_address')"
        :subtitle="$t('views.seller.create_product.views.event.event_address_1')"
        :showButton="false"
      />
    </b-col>
    <!-- Event Location Name -->
    <b-col cols="12">
      <b-form-group class="mr-md-3 mb-3"  :label="$t('seller.products.new.text_671')" label-for="location">
        <b-form-input
          data-test="location"
          id="location"
          name="location"
          v-model="location"
          type="text"
          :placeholder="$t('seller.products.new.text_672')"
          :class="{'heaven': isHeaven}"
          v-validate="'required'"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('location')">
          {{ $t('seller.address.listagem_enderecos.location') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <!-- Event Zip Code -->
    <b-col cols="12" lg="4" md="12">
      <b-form-group class="mr-md-3 mb-3" :label="$t('seller.address.listagem_enderecos.postal_code')" label-for="zip_code">
        <b-form-input
          data-test="zip_code"
          id="zip_code"
          name="zip_code"
          v-model="zip_code"
          type="text"
          :placeholder="$t('seller.address.listagem_enderecos.postal_code')"
          :class="{'heaven': isHeaven}"
          v-validate="'required'"
          @keyup="getCEP"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('zip_code')">
          {{ $t('seller.address.listagem_enderecos.postal_code') }} Preencha seu CEP.
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <!-- Event Street -->
    <b-col cols="12" lg="5" md="12">
      <b-form-group class="mr-md-3 mb-3"  :label="$t('seller.address.listagem_enderecos.public_place')" label-for="street">
        <b-form-input
          data-test="street"
          id="street"
          name="street"
          v-model="street"
          type="text"
          :placeholder="$t('seller.address.listagem_enderecos.type_public_place')"
          :class="{'heaven': isHeaven}"
          v-validate="'required'"
          @keyup="debounce('street')"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('street')">
          {{ $t('seller.address.listagem_enderecos.fill_public_place') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <!-- Event Number -->
    <b-col cols="12" lg="3" md="12">
      <b-form-group class="mb-3" :label="$t('seller.address.listagem_enderecos.number')" label-for="number">
        <b-form-input
          data-test="number"
          ref="numberAddress"
          name="number"
          v-model="number"
          type="number"
          :placeholder="$t('seller.address.listagem_enderecos.number')"
          :class="{'heaven': isHeaven}"
          v-validate="'required'"
          @focus="preventE"
          @blur="allowE"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('number')">
          {{ $t('seller.address.listagem_enderecos.fill_number') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <!-- Event City -->
    <b-col cols="12" md="6">
      <b-form-group class="mr-md-3 mb-3"  :label="$t('seller.address.listagem_enderecos.city')" label-for="city">
        <b-form-input
          data-test="city"
          id="city"
          name="city"
          v-model="city"
          type="text"
          :placeholder="$t('seller.address.listagem_enderecos.city')"
          :class="{'heaven': isHeaven}"
          v-validate="'required'"
          @keyup="debounce('city')"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('city')">
          {{ $t('seller.address.listagem_enderecos.fill_city') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <!-- Event Neighborhood -->
    <b-col cols="12" md="6">
      <b-form-group class="mb-3" :label="$t('seller.address.listagem_enderecos.neighborhood')" label-for="neighborhood">
        <b-form-input
          data-test="neighborhood"
          id="neighborhood"
          name="neighborhood"
          v-model="neighborhood"
          type="text"
          :placeholder="$t('seller.address.listagem_enderecos.neighborhood')"
          :class="{'heaven': isHeaven}"
          v-validate="'required'"
          @keyup="debounce('neighborhood')"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('neighborhood')">
          {{ $t('seller.address.listagem_enderecos.fill_neighborhood') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <!-- Event Complement -->
    <b-col cols="12" lg="7" md="12">
      <b-form-group class="mr-md-3 mb-3"  :label="$t('seller.address.listagem_enderecos.complement')" label-for="complement">
        <b-form-input
          data-test="complement"
          id="complement"
          name="complement"
          v-model="complement"
          type="text"
          :placeholder="$t('seller.address.listagem_enderecos.complement_of_address')"
          :class="{'heaven': isHeaven}"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('complement')">
          {{ $t('seller.address.listagem_enderecos.fill_complement') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <!-- Event State -->
    <b-col cols="12" lg="5" md="12">
      <b-form-group class="mb-3" :label="$t('seller.address.listagem_enderecos.state')" label-for="state">
        <b-form-input
          data-test="state"
          id="state"
          name="state"
          v-model="state"
          type="text"
          :placeholder="$t('seller.address.listagem_enderecos.state')"
          :class="{'heaven': isHeaven}"
          v-validate="'required'"
          @keyup="debounce('state')"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('state')">
          {{ $t('seller.address.listagem_enderecos.fill_state') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import _ from "lodash";

import CustomHeader from '../shared/CustomHeader.vue'

export default {
  name: 'EventAddressForm',
  components:{
    CustomHeader
  },
  computed: {
    ...mapGetters(['callValidateFields']),
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    zip_code: {
      get() { return this.$store.getters['getProductZipCode'] },
      set(value) { this.$store.dispatch('updateProductZipCode', value) }
    },
    street: {
      get() { return this.$store.getters['getProductStreet'] },
      set(value) { this.$store.dispatch('updateProductStreet', value) }
    },
    number: {
      get() { return this.$store.getters['getProductNumber'] },
      set(value) { this.$store.dispatch('updateProductNumber', value) }
    },
    city: {
      get() { return this.$store.getters['getProductCity'] },
      set(value) { this.$store.dispatch('updateProductCity', value) }
    },
    neighborhood: {
      get() { return this.$store.getters['getProductNeighborhood'] },
      set(value) { this.$store.dispatch('updateProductNeighborhood', value) }
    },
    complement: {
      get() { return this.$store.getters['getProductComplement'] },
      set(value) { this.$store.dispatch('updateProductComplement', value) }
    },
    state: {
      get() { return this.$store.getters['getProductState'] },
      set(value) { this.$store.dispatch('updateProductState', value) }
    },
    location: {
      get() { return this.$store.getters['getProductLocation'] },
      set(value) { this.$store.dispatch('updateProductLocation', value) }
    }
  },
  watch: {
    callValidateFields() {
      this.validateFields()
    }
  },
  methods: {
    preventE(event) {
      this.$refs.numberAddress.$el.addEventListener('keydown', this.blockE);
    },
    allowE() {
      this.$refs.numberAddress.$el.removeEventListener('keydown', this.blockE);
    },
    blockE(event) {
      const blockedKeys = [69,192, 222, 220, 221, 219, 186, 187, 189, 190, 191];
      if (blockedKeys.includes(event.keyCode)) {
        event.preventDefault();                                                     
      }
    },
    debounceNumber: _.debounce(function (event) {
      this.resetNumber(event);
    }, 500),
    debounce: _.debounce(function (variable) {
      this.validateFieldsWithoutFinish()
      this.resetText(variable);
    }, 500),
    resetNumber(event){
      this.number = event.replace(/\D/g, '');
    },
    resetText(variable) {
      this[variable] = this[variable].replace(/[0-9]/g, '');
   },
    validateFields() {
      this.$validator.validateAll().then((result) => {
        this.$store.commit('setEventFildsResult', result)
        this.$emit("finishValidation", result)
      })
    },
    validateFieldsWithoutFinish() {
      this.$validator.validateAll().then((result) => {
        this.$store.commit('setEventFildsResult', result)
      })
    },
    getCEP() {
      this.validateFieldsWithoutFinish()
      const cep = this.zip_code
      if(cep && cep.replace("-", "").length == 8) {
        axios.get(`https://viacep.com.br/ws/${cep.replace("-","")}/json`)
          .then(res => {
            if(res.data.erro){
              this.$bvToast.toast(this.$t('seller.address.listagem_enderecos.fill_postal_code_3'), {
                title: this.$t('seller.address.listagem_enderecos.postal_code'),
                variant: 'warning',
                autoHideDelay: 5000,
                appendToast: true
              })
            } else {
              this.street = this.street ? this.street : res.data.logradouro ?? ''
              this.neighborhood = this.neighborhood ? this.neighborhood : res.data.bairro ?? ''
              this.city = res.data.localidade ?? ''
              this.state = res.data.uf ?? ''
              setTimeout(() => {
                this.$refs.numberAddress.focus()
              })
            }
          })
          .catch(err => console.error(err))
      }
    }
  },
  mounted() {
    this.validateFieldsWithoutFinish()
  }
}
</script>

<style scoped lang="scss">
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>