import Rest from '@/services/Rest';

/**
 * @typedef {CategoryService}
 */
export default class CategoryService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/custom-thankyou-page'
}
