<template>
  <div v-if="!loading">
    <list-item
      title="G Digital 1.0"
      :list="old_list"
      @delete="removeGdigital($event, 'old')"
    />
  
    <list-item
      title="Greenn Sales"
      :list="new_list"
      @delete="removeGdigital($event, 'new')"
    />
  </div>

  <div class="container-loading" v-else>
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>
import Vue from "vue";
import { EventBus } from "@/main.js";
// Components
import ListItem from "@/views/Seller/UpdateProduct/shared/ListItem.vue";
// Services
import { api_gdigital } from "@/services/Gdigital.js";
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  name: "ListagemGdigital",
  components: {
    ListItem,
  },
  data() {
    return {
      loading: false,
      old_list: [],
      new_list: [],
      hasIntegration: false,
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  methods: {
    async init() {
      this.loading = true;
      await api_gdigital
        .get("/integration")
        .then((r) => {
          this.hasIntegration = !!r.data.length;
        })
        .catch(() => {
          this.hasIntegration = false;
        })
        .finally(() => {
          this.loading = false;
          if (this.hasIntegration) this.fetchCallbacks();
        });
    },
    async fetchCallbacks() {
      this.loading = true;
      let data = {
       product_id: this.id_product || this.$route.params.id_product,
        type: "gdigital",
      };
      await serviceCallback
        .search(data)
        .then((r) => {
          this.old_list = [...r.old_g]
            .filter((item) => item.type === "gdigital")
            .map((item) => ({
              id: item.id,
              icon: "document.svg",
              name: this.convertTrigger(item.trigger),
              description: JSON.parse(item.args).url,
              actions: ["delete"],
            }));

          this.new_list = [...r.new_g]
            .filter((item) => item.type === "gdigital")
            .map((item) => ({
              id: item.id,
              icon: "document.svg",
              name: this.convertTrigger(item.trigger),
              description: JSON.parse(item.args).url,
              actions: ["delete"],
            }));
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async removeGdigital(payload, type = 'old') {
      const { index, name, id } = payload;
      Vue.swal({
        title: "Remover Evento ",
        text: `Deseja continuar com a remoção do #${id} - ${name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("seller.products.listagem_active.text_1940"),
        cancelButtonText: this.$t("client.text_51"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;

          let data = {
            id: id,
          };
          await serviceCallback
            .destroy(data)
            .then(async(response) => {
              if (response == 1) {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_gdigital.text_1947"),
                  {
                    title: "Greenn Sales",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                // atualizando front
                type == 'old' ? this.old_list.splice(index, 1) : this.new_list.splice(index, 1);
                await this.init();
              } else {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_gdigital.text_1948"),
                  {
                    title: "Greenn Sales",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    convertTrigger(trigger) {
      switch (trigger) {
        //  vendas
        case "salePaid":
          return this.$t("seller.products.options_sales.text_751");
        case "saleRefused":
          return this.$t("seller.products.options_sales.text_752");
        case "saleRefunded":
          return this.$t("seller.products.options_sales.text_753");
        case "BOLETO":
          return this.$t("seller.products.options_saleswaiting.text_758");
        case "PIX":
          return this.$t("seller.products.options_saleswaiting.text_759");
        case "saleChargedback":
          return this.$t("seller.products.options_sales.text_754");
        case "saleUnpaid":
          return this.$t("seller.products.options_sales.text_755");
        case "saleWaitingPayment":
          return this.$t("seller.products.options_sales.text_756");

        //  assinaturas
        case "contractUnpaid":
          return this.$t("seller.products.options_sub.text_773");
        case "contractPaid":
          return this.$t("seller.products.options_sub.text_774");
        case "contractTrialing":
          return this.$t("seller.products.options_sub.text_775");
        case "contractPendingPayment":
          return this.$t("seller.products.options_sub.text_776");
        case "contractEnded":
          return this.$t("seller.products.options_sub.text_777");

        //  checkout
        case "checkoutAbandoned":
          return this.$t("seller.products.options_checkout.text_779");

        //  venda recusada
        case "INVALID_CREDIT_CARD":
          return this.$t("seller.products.options_salesrefused.text_761");
        case "INSUFFICIENT_FUNDS":
          return this.$t("seller.products.options_salesrefused.text_762");
        case "INVALID_PAYMENT_TYPE":
          return this.$t("seller.products.options_salesrefused.text_763");
        case "INVALID_DATA":
          return this.$t("seller.products.options_salesrefused.text_764");
        case "INVALID_CLIENT_DATA":
          return this.$t("seller.products.options_salesrefused.text_765");
        case "INVALID_CVV":
          return this.$t("seller.products.options_salesrefused.text_766");
        case "BANK":
          return this.$t("seller.products.options_salesrefused.text_767");
        case "CREDIT_CARD_OPERATOR":
          return this.$t("seller.products.options_salesrefused.text_768");
        case "INVALID_INSTALLMENTS":
          return this.$t("seller.products.options_salesrefused.text_769");
        case "CURRENCY_NOT_SUPPORTED":
          return this.$t("seller.products.options_salesrefused.text_770");
        case "SUSPECTED_FRAUD":
          return this.$t("seller.products.options_salesrefused.text_771");
        case "GENERIC":
          return this.$t("seller.products.options_salesrefused.text_772");
        default:
          return trigger;
      }
    },
  },
  async mounted() {
    await this.init();
     EventBus.$on("reloadGdigitalIntegration", async () => {
      await this.init();
     });
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
/* lista de entregas */
.lista-entrega h5 {
  margin-bottom: 20px;
  color: #2133d2;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: capitalize;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img {
  width: 20px;
  margin-right: 30px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 400px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
}
.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}

.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}
/* // co-seller */
.co-seller-item {
  width: 90%;
}
.info-co-seller {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.info-co-seller h5 {
  margin: 0;
}
.info-co-seller .separacao-co-seller {
  display: block;
}
.info-co-seller .separacao-co-seller:last-child {
  text-align: right;
}
.info-co-seller .separacao-co-seller h5 {
  color: #333;
}
.info-co-seller .separacao-co-seller p {
  margin-top: 5px;
}
</style>