import store from '@/store/index'
import gtagSend from "@/utils/gtagSend.js";
import moment from 'moment';
import _ from 'lodash';

function mapFieldsData() {
  const differences = {};
  const beforeEdit = store.getters.productBeforeEdit;
  const afterEdit = store.getters.product;


  // Function to find and record differences
  Object.keys(afterEdit).forEach(key => {
    
    if (!_.isEqual(afterEdit[key], beforeEdit[key]) && key !== 'fiscal_center_setting') {
      differences[key] = { old: beforeEdit[key], new: afterEdit[key] };
    }
  });

  return differences;
}

export default function getFieldsUpdata(){   
    
  let objectParans = mapFieldsData();

  gtagSend('produto_editado',{...objectParans, 
    data_hora_do_acesso:moment().format('DD/MM/YYYY HH:mm:ss'),
    ID_Produto: store.getters.productId,
    ID_Cliente: store.getters.getProfile.id,
    titulo_do_produto: store.getters.productName
  })
}