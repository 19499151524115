<template>
  <section data-anima="top">
    <custom-header
      :title="$t('views.seller.edit_product.fiscal.title')"
      :subtitle="$t('views.seller.edit_product.fiscal.subtitle')"
      :show-button="false"
    />

    <section class="Advanced__Fiscal">
      <section class="custom-container" data-anima="left">
        <!-- Fiscal Category -->
        <section class="custom-box left" :class="{ 'fix-width': category_fiscal === 'PRODUCT' }">
          <b-form-group label="" label-for="allow_proposal">
            <p>{{ $t('views.seller.edit_product.text_1107') }}</p>
            <div>
              <b-form-select
                v-model="category_fiscal"
                :options="[
                  { value: 'PRODUCT', text: $t('views.seller.edit_product.text_2270') },
                  { value: 'SERVICE', text: $t('views.seller.edit_product.text_2271') }
                ]"
                :class="{ heaven: isHeaven }"
              ></b-form-select>
            </div>
          </b-form-group>
        </section>
        <!-- Fiscal Code -->
        <section class="custom-box right" v-if="category_fiscal === 'SERVICE' || (category_fiscal === 'PRODUCT' && !enable_invoice_items)">
          <p>{{ category_fiscal === 'PRODUCT' && !enable_invoice_items ? $t('views.seller.edit_product.text_3022') : $t('views.seller.edit_product.text_1108') }}</p>
          <input
            v-model="fiscal_code"
            type="text"
            :placeholder="category_fiscal === 'PRODUCT' && !enable_invoice_items ? '' : '####'"
          />
        </section>
      </section>
      <section v-if="category_fiscal === 'PRODUCT'" class="custom-box left w-100">
        <!-- Enable Invoice Items -->
        <FormItem
          :title="$t('views.seller.edit_product.text_3019')"
          :sub-title="enable_invoice_items ? (sigameDigitalAccess ? $t('views.seller.edit_product.text_3020') : $t('views.seller.edit_product.text_3020_1')) : ''"
          :disableBorder="false"
          class="mb-2"
        >
          <b-form-checkbox :class="{'hide-subtitle': !enable_invoice_items}" name="enable_invoice_items" size="lg" switch v-model="enable_invoice_items" />
        </FormItem>
        <div v-if="enable_invoice_items" class="d-flex justify-content-between">
          <!-- Total Percent -->
          <div>
            <span class="custom-text-semibold">{{ $t('views.seller.edit_product.text_3021') }}</span>
            <span class="custom_percent_sun ml-3" :class="{'custom_percent_sun_100': percent_sun === 100}">
              {{ percent_sun }} %
            </span>
          </div>
          <!-- Add Item -->
          <div>
            <base-button class="small-btn-custom" variant="info" @click="addItem">
              {{ $t('views.seller.edit_product.text_3025') }}
            </base-button>
          </div>
        </div>
        <!-- Items For Invoice -->
        <div
          v-if="enable_invoice_items"
          v-for="(item_for_invoice, i) in items_for_invoice"
          :key="'item_for_invoice_'+i"
          class="mb-3 mt-4"
        >
          <div class="d-flex">
            <div class="d-flex">
              <div class="mb-3 mr-2 mr-md-0 d-md-flex justify-content-between">
                <!-- Title -->
                <b-form-group class="mr-md-2 w-100 custom-responsive" :label="$t('seller.products.new.text_586')" label-for="title">
                  <b-form-input
                    id="title"
                    name="title"
                    v-model="item_for_invoice.title"
                    type="text"
                    :class="{ heaven: isHeaven }"
                  ></b-form-input>
                </b-form-group>
                <!-- NCM -->
                <b-form-group class="mr-md-2 custom-responsive" :label="$t('views.seller.edit_product.text_3022')" label-for="ncm">
                  <b-form-input
                    id="ncm"
                    name="ncm"
                    v-model="item_for_invoice.ncm"
                    type="text"
                    :class="{ heaven: isHeaven }"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="d-md-flex justify-content-between">
                <!-- Quantity -->
                <b-form-group class="mr-md-2 w-100" :label="$t('views.seller.edit_product.text_3023')" label-for="quantity">
                  <b-form-input
                    id="quantity"
                    name="quantity"
                    v-model="item_for_invoice.quantity"
                    type="number"
                    :class="{'heaven': isHeaven}"
                  ></b-form-input>
                </b-form-group>
                <!-- Percent -->
                <b-form-group class="w-100" :label="$t('views.seller.edit_product.text_3024')" label-for="percent">
                  <b-form-input
                    id="percent"
                    name="percent"
                    v-model="item_for_invoice.percent"
                    type="number"
                    min="0"
                    max="100"
                    :class="{'heaven': isHeaven}"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <!-- Remove Button -->
            <div v-if="i !== 0" class="mt-4 ml-3 d-flex align-items-center">
              <div class="remove-custom mb-2" @click="removeItem(i)">
                <img src="@/assets/img/icons/trash.svg" alt="trash">
              </div>
            </div>
            <!-- Fill the empty space -->
            <div v-else class="mt-4 ml-3 d-flex align-items-center">
              <div class="remove-custom-hidden" @click="removeItem(i)">
              </div>
            </div>
          </div>
          <hr v-if="items_for_invoice.length !== (i+1)" class="mt-3">
        </div>
      </section>
    </section>
    <hr class="mt-3">
    <!-- Emissão após garantia -->
    <FormItem
      :title="$t('views.seller.edit_product.advanced.fiscal.emit_title')"
      :sub-title="$t('views.seller.edit_product.advanced.fiscal.emit_desc')"
    >
      <b-form-checkbox size="lg" switch v-model="generate_after_warranty" />
    </FormItem>
    <!-- Emissão de notas por co-produtores -->
    <FormItem
      :title="$t('views.seller.edit_product.advanced.fiscal.produces_title')"
      :sub-title="$t('views.seller.edit_product.advanced.fiscal.produces_desc')"
    >
      <b-form-checkbox size="lg" switch v-model="co_producer_will_issue_note" />
    </FormItem>
  </section>
</template>

<script>
// Components
import CustomHeader from "../shared/CustomHeader.vue";
import FormItem from "../shared/FormItem.vue";

// Core
import { mapGetters } from "vuex";

// Mixins
import Money from "@/mixins/money";

export default {
  name: 'Fiscal',
  components: { CustomHeader, FormItem },
  mixins: [Money],
  data() {
    return {
      items_for_invoice: [{
        title: null,
        quantity: null,
        percent: null,
        ncm: null
      }]
    }
  },
  watch: {
    items_for_invoice: {
      handler() {
        this.teleportData();
      },
      deep: true
    },
    category_fiscal() {
      if(this.category_fiscal === 'SERVICE') {
        this.enable_invoice_items = false
        this.items_for_invoice = [{
          title: null,
          quantity: null,
          percent: null,
          ncm: null
        }]
      }
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "product",
      "productId",
      "isLoading",
      "fiscalCenterSetting"
    ]),
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    percent_sun() {
      let sun = 0
      if(Array.isArray(this.items_for_invoice)) {
        this.items_for_invoice.forEach(x => {
          if(x && x.percent) sun += parseFloat(x.percent)
        })
      }
      return sun
    },
    sigameDigitalAccess() {
      const userIds = process.env.SIGAME_DIGITAL_ACCESS && process.env.SIGAME_DIGITAL_ACCESS != 'undefined' ? JSON.parse(process.env.SIGAME_DIGITAL_ACCESS) : []
      return userIds.some(x => parseInt(x) === parseInt(this.$store.getters.getProfile.id))
    },
    fiscal_code: {
      get() {
        return this.$store.state.product.product.fiscal_code;
      },
      set(fiscal_code) {
        this.$store.commit("setProduct", { ...this.product, fiscal_code });
      },
    },
    category_fiscal: {
      get() {
        return this.$store.state.product.product.category_fiscal;
      },
      set(category_fiscal) {
        this.$store.commit("setProduct", { ...this.product, category_fiscal });
      },
    },
    generate_after_warranty: {
      get() {
        return this.$store.getters["fiscalCenterSetting"].generate_after_warranty;
      },
      set(generate_after_warranty) {
        this.$store.commit("setGenerateAfterWarranty", generate_after_warranty);
      },
    },
    co_producer_will_issue_note: {
      get() {
        return this.$store.getters["fiscalCenterSetting"].co_producer_will_issue_note;
      },
      set(co_producer_will_issue_note) {
        this.$store.commit("serCoproducerWillIssueNote", co_producer_will_issue_note);
      },
    },
    enable_invoice_items: {
      get() {
        return this.$store.state.product.product.enable_invoice_items;
      },
      set(enable_invoice_items) {
        this.$store.commit("setProduct", { ...this.product, enable_invoice_items });
      },
    },
  },
  methods: {
    teleportData() {
      this.$store.commit('setItemsForInvoice', this.items_for_invoice)
    },
    addItem() {
      this.items_for_invoice.push({
        title: null,
        quantity: null,
        percent: null,
        ncm: null
      })
    },
    removeItem(i) {
      this.items_for_invoice.splice(i, 1)
    }
  },
  mounted() {
    if(this.product && this.product.enable_invoice_items) {
      let arr = []
      this.product.items_for_invoice.forEach(x => {
        arr.push(Object.assign({}, x))
      })
      this.items_for_invoice = arr
    }
  }
}
</script>

<style scoped lang="scss">

.hide-subtitle {
  position: absolute;
  right: -10px;
  top: -32px;
}
.ncm-custom {
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .custom-responsive {
    width: 100% !important;
  }
}
.plus-custom {
  background-color: $secondary-color;
  border: 0 solid transparent;
  border-radius: 9999px;
  font-size: 24px;
  font-weight: 600;
  color: white;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.plus-custom:hover {
  transform: scale(1.1);
}

.remove-custom {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.remove-custom-hidden {
  width: 25px;
}

.remove-custom:hover {
  transform: scale(1.1);
}
.custom_percent_sun {
  background-color: rgba(255, 158, 68, 0.1);
  color: #FF9E44;
  border: 0 solid transparent;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 10px;
}
.custom_percent_sun_100 {
  background-color: rgba(0, 148, 136, 0.1);
  color: #009488;
}

.small-btn-custom {
  padding: 0 20px !important;
  height: 40px !important;
  font-size: 12px;
}

.custom-text-semibold {
  font-weight: 600;
}
</style>
