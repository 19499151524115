<template>
  <list-item
    v-if="!loading"
    title="Greenn Envios"
    :list="list"
    @delete="removeEnvios"
  />
  <div class="container-loading" v-else>
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";

import Vue from "vue";
import moment from "moment";
// Components
import ListItem from "@/views/Seller/UpdateProduct/shared/ListItem.vue";
// Services
import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();
export default {
  name: "ListagemEnvios",
  components: {
    ListItem,
  },
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  props: {
    id_product: {
      type: [Number, String],
    },
  },
  methods: {
    async fetchList() {
      let data = {
        product_id: this.id_product || this.$route.params.id_product,
        type: "envios",
        url: "list",
      };
      return await serviceIntegration.search(data);
    },
    async removeEnvios(payload) {
      const { id } = payload;
      Vue.swal({
        title: this.$t("seller.products.listagem_envios.text_521"),
        text: this.$t("seller.products.listagem_envios.text_522"),
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("seller.products.listagem_active.text_1940"),
        cancelButtonText: this.$t("client.text_51"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let data = {
            id,
          };

          await serviceIntegration
            .destroy(data)
            .then(async(response) => {
              if (response.success) {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_envios.text_523"),
                  {
                    title: "Greenn Envios",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                await this.init();
              } else {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_envios.text_524"),
                  {
                    title: "Greenn Envios",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    async init() {
      this.loading = true;
      await this.fetchList()
        .then((response) => {
          this.list = response
            .filter((item) => item.type == "envios")
            .map((item) => ({
              ...item,
              name: `#${item.id} - ${item.data.conexoes.text}`,
              id: item.id,
              icon: "envios.svg",
              actions: ["delete"],
              description: `${this.$t('seller.products.voxuy.text_742')} ${moment(item.created_at).locale("pt-br").format("DD/MM/YYYY - HH:mm")}`
            }));
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.init();
    EventBus.$on("reloadEnviosIntegration", async () => {
      await this.init(); 
    });
  },
};
</script>
