<template>
  <section class="integrations">
    <custom-header
      :title="$t('views.seller.edit_product.text_1116')"
      :subtitle="$t('views.seller.edit_product.text_1117')"
      :button-text="$t('views.seller.edit_product.text_1118')"
      @action="$bvModal.show('delivery-options')"
    />
    
    <section class="integrations__body" v-if="!listLoading">

      <!-- GDigital -->
      <gdigital-list :id_product="productId" />

      <!-- Google Sheets -->
      <google-sheets-list :id_product="productId" />

      <!-- Voxuy -->
      <voxuy-list :id_product="productId" />

      <!-- Lead Lovers -->
      <leadlovers-list :id_product="productId" />

      <!-- Conta Azul -->
      <conta-azul-list 
      :id_product="productId"
      :create_contaazul="create_contaazul"
      :product_type="product.type"
      @update-create-contaazul="(n) => (create_contaazul = n)"
      @edit-contaazul="(n) => (edit_contaazul = n)"
      />

      <!-- Infusion Soft -->
      <infusion-soft-list :id_product="productId" />

      <!-- Active Campaign -->
      <active-campaign-list :id_product="productId" />
      <!-- Grenn Envios -->
      <envios-list :id_product="productId" />
    </section>
  </section>    
</template>

<script>
// Components
import CustomHeader from "./shared/CustomHeader.vue";
import GdigitalList from "@/components/Seller/Products/modals/ListagemGdigital.vue";
import GoogleSheetsList from "@/components/Seller/Products/modals/ListagemSheets.vue";
import VoxuyList from "@/components/Seller/Products/modals/ListagemVoxuy.vue";
import LeadloversList from "@/components/Seller/Products/modals/ListagemLeadlovers.vue";
import ContaAzulList from "@/components/Seller/Products/modals/ListagemContaAzul.vue";
import InfusionSoftList from "@/components/Seller/Products/modals/ListagemInfusionsoft.vue";
import ActiveCampaignList from "@/components/Seller/Products/modals/ListagemActiveCampaign.vue";
import EnviosList from "@/components/Seller/Products/modals/ListagemEnvios.vue";
// core
import { mapGetters } from "vuex";

export default {
  components: {
    CustomHeader,
    GdigitalList,
    GoogleSheetsList,
    VoxuyList,
    LeadloversList,
    ContaAzulList,
    InfusionSoftList,
    ActiveCampaignList,
    EnviosList
  },
  computed: {
    ...mapGetters(["product", "productId", "listLoading"]),
  },
  methods: {

  },
  data() {
    return {
      create_contaazul: false,
      edit_contaazul: false,
    }
  },
  mounted() {
    if(this.$route.query.callback && this.$route.query.callback === 'contaazul') {
      setTimeout(() => {
        this.$bvModal.show('delivery-options')
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.integrations {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
</style>