<template>
    <section class="Advanced">
        <section class="Advanced__tabs" :class="{ heaven: isHeaven }">
            <b-tabs class="w-100" content-class="w-100" nav-wrapper-class="w-100">
                <b-tab v-for="(tab, index) in tabs.filter((item) => item.show)" :key="index" lazy
                    @click="selectedTab = tab">
                    <template #title>
                        {{ tab.title }}
                    </template>
                </b-tab>
            </b-tabs>
        </section>

        <component :is="selectedTab.component" />
    </section>
</template>
<script>
// Components
import CustomHeader from "./shared/CustomHeader.vue";
import FormItem from "./shared/FormItem.vue";

// Tabs component
import Fiscal from "./Advanced/Fiscal.vue";
import General from "./Advanced/General.vue";
import Tracking from "./Advanced/Tracking.vue";


// Core
import { mapGetters } from "vuex";

// Mixins
import Money from "@/mixins/money";
import Permissions from "@/utils/permissions.js";

export default {
    components: {
        CustomHeader,
        FormItem,
        Fiscal,
        General,
        Tracking
    },
    computed: {
        ...mapGetters([
            "isLoading"
        ]),
        isHeaven() {
            return this.$store.getters["getIsHeaven"];
        },
        tabs() {
            return [
                {
                    title: this.$t("views.seller.edit_product.tabs.advanced_tabs.general"),
                    component: "General",
                    show: Permissions.hasPermission("EditGeneralProduct"),
                },
                {
                    title: this.$t("views.seller.edit_product.tabs.advanced_tabs.tracking"),
                    component: "Tracking",
                    show: Permissions.hasPermission("EditProductTracking"),
                },
                {
                    title: this.$t("views.seller.edit_product.tabs.advanced_tabs.fiscal"),
                    component: "Fiscal",
                    show: Permissions.hasPermission("EditProductTaxSetup") && !this.isHeaven,
                },

            ]
        } 
    },
    data() {
        return {
            Permissions,
            selectedTab: {
                title: this.$t("views.seller.edit_product.tabs.basic_informations"),
                component: "General",
                show: Permissions.hasPermission("EditGeneralProduct"),
            },
            
        }
    },
    methods: {
        changeCurrentTab(tab) {
            this.current_tab = tab;
        },
    },
    mixins: [Money],
};
</script>
<style lang="scss">
.advanced-top {
    margin-top: 1.56rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: $base-color !important;
}

.select-producer {
    max-width: 200px;
}

.fix-width {
    width: 100% !important;
}

.h5-title {
    color: #333;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 1rem;
}

.remove-page {
    display: block;
    right: 0.3rem;
    position: absolute;
}

.remove-page a {
    font-weight: 600;
    color: #ff0c37;
    font-size: 13px;
}

.remove-page a:hover {
    text-decoration: none;
}

.outline-button {
    @extend .default-font;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.125rem;
    color: $secondary-color;
    font-size: 0.8375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: none;
    padding: 10px;
    cursor: pointer;
    outline: inherit;
    border-radius: 0.625rem;
    border: 1px solid $secondary-color;
}

.Advanced {
    .custom-container {
        display: flex;
    }

    .header {
        margin-top: 2.5rem;
        margin-bottom: 1.88rem;
    }

    .custom-box {
        width: 50%;
    }

    .custom-box.right {
        position: relative;
        padding-left: 10px;
    }

    .custom-box.left {
        position: relative;
        padding-right: 10px;
    }

    p {
        color: #333;
        @extend .default-font;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 1rem;
    }

    input,
    select {
        color: #81858E;
        @extend .default-font;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        width: 100%;
    }

    .default-input {
        color: #81858E;
        @extend .default-font;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        width: 100%;
    }

    &__tabs {
        width: 100%;
        margin-bottom: 2rem;
    }

    &__thankYouPage {
        margin-top: 2rem;
    }

    &__Fiscal {
        margin-top: 2rem;
    }
}

// Tracking

.icon-lixeira,
.icon-editar {
    width: 14px;
    filter: invert(50%);
    cursor: pointer;
}

.icon-editar {
    margin-right: 15px !important;
}

.opcoes-entrega li:hover {
    font-weight: 600;
    background: #f7f7f7;
}

.icon-lixeira:hover,
.icon-editar:hover {
    filter: invert(0) !important;
}

.container-pixels {
    max-height: 500px;
    overflow: auto;
    padding: 10px;
}

.pixel {
    padding: 15px;
    border: 1px solid #ededf0;
    border-radius: 5px;
}

.pixel+.pixel {
    margin-top: 10px;
}

.pixel .iconePixel {
    margin: 15px 0 20px 0;
    max-width: 170px;
}

.pixel span {
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 15px;
}

.pixel div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pixel div.pixel-type {
    display: block;
}

.pixel-status {
    flex-wrap: wrap;
    justify-content: flex-start !important;
    gap: 10px;
}

.status-pixel {
    font-size: 11px !important;
    font-weight: normal !important;
    background: #f7f7f7;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0 !important;
}

.status-pixel.ativada {
    background: #00948820;
    color: $base-color;
}

.status-pixel.heaven.ativada {
    background: #00948820;
    color: $base-color-heaven;
}

.pixel p {
    font-size: 13px;
}

input#pixel_id,
select#type_pixel,
.btn-pixel {
    padding: 0 20px !important;
    height: 40px !important;
    font-size: 12px;
}

.pixel h6 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 15px;
    margin: 10px 0;
    color: $secondary-color;
}

.pixel h6 img {
    filter: invert(50%);
}
</style>