<template>
  <section class="contents">
    <custom-header
      :title="$t('views.seller.edit_product.text_1075')"
      :subtitle="$t('views.seller.edit_product.text_1076')"
      button-text="Adicionar conteúdo"
      @action="$bvModal.show('delivery-options')"
    />
    <section class="contents__body" v-if="!listLoading">
      <!-- Arquivos -->
      <files-list :id_product="productId" :lista="attachments" />
      <!-- Memberkit -->
      <memberkit-list :id_product="productId" />
      <!-- Alpaclass -->
      <alpaclass-list :id_product="productId" />
      <!-- Webhook -->
      <webhook-list :id_product="productId" />
      <!-- Greenn Club -->
      <greenn-club :id_product="productId" />
    </section>
  </section>
</template>
<script>
// Components
import CustomHeader from "./shared/CustomHeader.vue";
import FilesList from "@/components/Seller/Products/modals/ListagemArquivos.vue";
import MemberkitList from "@/components/Seller/Products/modals/ListagemMemberkit.vue";
import AlpaclassList from "@/components/Seller/Products/modals/ListagemAlpaclass.vue";
import WebhookList from "@/components/Seller/Products/modals/ListagemWebhook.vue";
import GreennClub from "@/components/Seller/Products/modals/ListagemClub";
// core
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    CustomHeader,
    FilesList,
    MemberkitList,
    AlpaclassList,
    WebhookList,
    GreennClub,
  },
  computed: {
    ...mapGetters(["productId", "attachments", "listLoading"]),
  },
  methods: {
    ...mapActions(["fetchProduct"]),
  },
  watch: {
    async listLoading(val) {
      if (!val) {
        await this.fetchProduct(this.productId);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contents {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
</style>