<template>
  <section>
    <list-item v-if="!loading" title="Conta Azul" :list="list" @update="edit" @delete="remove" />
    <div class="container-loading" v-else>
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <conta-azul v-if="!create_contaazul" :id_product="id_product" :isEdit="validateEdit" @close="atualizarContaAzul" :callback="callback"
      :product_type="product_type || null" />
  </section>
</template>

<script>
import ContaAzul from "@/components/Seller/Products/modals/contaAzul.vue";
import ListItem from "@/views/Seller/UpdateProduct/shared/ListItem.vue";
// helper:
import { EventBus } from "@/main.js";
// services:
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  name: "ContaAzulList",
  components: { ContaAzul, ListItem },
  props: {
    id_product: {
      type: Number,
    },
    integracao: {
      type: Boolean,
    },
    create_contaazul: {
      type: Boolean,
    },
    product_type: {
      required: false,
    },
  },
  data() {
    return {
      validateEdit: false,
      loading: false,
      showList: true,
      response_webhook: {},
      lista: [],
      url_edit: null,
      maxLength: 48,
      callback: null,
    };
  },
  computed: {
    list() {
      return this.lista.map((item) => ({
        ...item,
        id: item.id,
        icon: "checkItem.svg",
        name: `${item.id} - ${item.name}`,
        actions: ["update", "delete"],
      }));
    },
  },
  methods: {
    async atualizarContaAzul() {
      this.validateEdit = false;
      this.callback = null;
      this.$emit("contaazul-list");
      this.$emit("update-create-contaazul", false);
      this.$emit("edit-contaazul", false);
      await this.fetchCallbacks();
    },
    edit(item) {
      this.$emit("edit-contaazul", true);
      this.validateEdit = true;
      this.callback = item;
      this.$bvModal.show("modal-contaazul");
    },
    async remove(id) {
      this.loading = true;
      await serviceCallback
        .destroy(id)
        .then(async() => {
          await this.fetchCallbacks();
        })
        .catch((err) => console.error(err));
    },
    // puxa todos os callbacks
    async fetchCallbacks() {
      this.loading = true;
      let data = {
        product_id: this.id_product || this.$route.params.id_product,
        type: "contaazul",
      };
      await serviceCallback
        .search(data)
        .then((r) => {
          this.lista = [];

          [...r].reverse().forEach((item) => {
            if (item.type == "contaazul") {
              this.lista.push({
                name: this.convertTrigger(item.trigger),
                id: item.id,
                obj: item,
              });
            }
          });
          EventBus.$emit("integracoesLista", this.lista.length);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false;
        });
    },
    convertTrigger(trigger) {
      switch (trigger) {
        //  vendas
        case "salePaid":
          return this.$t("seller.products.options_sales.text_751");
        case "saleRefused":
          return this.$t("seller.products.options_sales.text_752");
        case "saleRefunded":
          return this.$t("seller.products.options_sales.text_753");
        case "BOLETO":
          return this.$t("seller.products.options_saleswaiting.text_758");
        case "PIX":
          return this.$t("seller.products.options_saleswaiting.text_759");
        case "saleChargedback":
          return this.$t("seller.products.options_sales.text_754");
        case "saleUnpaid":
          return this.$t("seller.products.options_sales.text_755");
        case "saleWaitingPayment":
          return this.$t("seller.products.options_sales.text_756");

        //  assinaturas
        case "contractUnpaid":
          return this.$t("seller.products.options_sub.text_773");
        case "contractPaid":
          return this.$t("seller.products.options_sub.text_774");
        case "contractTrialing":
          return this.$t("seller.products.options_sub.text_775");
        case "contractPendingPayment":
          return this.$t("seller.products.options_sub.text_776");
        case "contractEnded":
          return this.$t("seller.products.options_sub.text_777");

        //  checkout
        case "checkoutAbandoned":
          return this.$t("seller.products.options_checkout.text_779");

        //  venda recusada
        case "INVALID_CREDIT_CARD":
          return this.$t("seller.products.options_salesrefused.text_761");
        case "INSUFFICIENT_FUNDS":
          return this.$t("seller.products.options_salesrefused.text_762");
        case "INVALID_PAYMENT_TYPE":
          return this.$t("seller.products.options_salesrefused.text_763");
        case "INVALID_DATA":
          return this.$t("seller.products.options_salesrefused.text_764");
        case "INVALID_CLIENT_DATA":
          return this.$t("seller.products.options_salesrefused.text_765");
        case "INVALID_CVV":
          return this.$t("seller.products.options_salesrefused.text_766");
        case "BANK":
          return this.$t("seller.products.options_salesrefused.text_767");
        case "CREDIT_CARD_OPERATOR":
          return this.$t("seller.products.options_salesrefused.text_768");
        case "INVALID_INSTALLMENTS":
          return this.$t("seller.products.options_salesrefused.text_769");
        case "CURRENCY_NOT_SUPPORTED":
          return this.$t("seller.products.options_salesrefused.text_770");
        case "SUSPECTED_FRAUD":
          return this.$t("seller.products.options_salesrefused.text_771");
        case "GENERIC":
          return this.$t("seller.products.options_salesrefused.text_772");
        default:
          return trigger;
      }
    },
  },
  async mounted() {
    await this.fetchCallbacks();
    EventBus.$on("reloadContaAzulIntegration", async () => {
      await this.fetchCallbacks(); 
    });
  },
};
</script>
