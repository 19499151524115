<template>
    <section data-anima="top">
        <section>
            <custom-header :title="$t('views.seller.edit_product.tracking.title')"
                :subtitle="$t('views.seller.edit_product.tracking.subtitle')" :show-button="false" />
        </section>

        <b-row>
            <!-- Metas -->
            <div class="mt-4 mt-md-0 col-12 col-md-6">
                <b-col class="container-pixels" v-if="!loading_meta">
                    <div class="d-flex justify-content-between align-content-center mb-3">
                        <h5 class="m-0 h5-title">Metas</h5>
                        <BaseButton variant="info2" id="metas" class="btn-pixel" v-if="!novaMeta" @click="novaMeta = true">
                            {{
                                $t("views.seller.edit_product.text_1098")
                            }}</BaseButton>
                    </div>
                    <b-form data-anima="top" class="mb-5" v-if="novaMeta">
                        <b-col cols="12">
                            <b-form-group :label="$t('views.seller.edit_product.text_2255')" label-for="chave_meta">
                                <b-form-input placeholder="Ex: utm_source" name="chave_meta" autocomplete="off"
                                    v-model="newMeta.key" type="text" :class="{ heaven: isHeaven }"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" class="mt-3">
                            <b-form-group :label="$t('views.seller.edit_product.text_2256')" label-for="valor_meta">
                                <b-form-input placeholder="Ex: fb" autocomplete="off" name="valor_meta"
                                    v-model="newMeta.value" type="text" :class="{ heaven: isHeaven }"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <div class="d-flex justify-content-end mt-3">
                            <BaseButton variant="link-info" id="pixels" class="btn-pixel" @click="cancelMeta">{{
                                $t("views.seller.edit_product.text_2257")
                            }}</BaseButton>
                            <BaseButton variant="black" id="pixels" class="btn-pixel ml-2" @click="addMeta">{{
                                $t("views.seller.edit_product.text_2259")
                            }}</BaseButton>
                        </div>
                    </b-form>
                    <div v-if="metas.length">
                        <div v-for="(meta, index) in metas" :key="meta.id" class="pixel" data-anima="top">
                            <div>
                                <h6>
                                    <img src="@/assets/img/icons/formato.svg" />
                                    #{{ meta.id }}
                                </h6>
                                <div>
                                    <img src="@/assets/img/icons/editar.svg" class="icon-lixeira lixeira-pixel mr-2"
                                        :id="'btn-edit-' + meta.id" @click="editMeta(meta)" />
                                    <b-tooltip :target="'btn-edit-' + meta.id" :title="$t('views.seller.edit_product.text_2259')
                                        " placement="left" />
                                    <img src="@/assets/img/icons/lixeira.svg" class="icon-lixeira lixeira-pixel"
                                        :target="'btn-remove-' + meta.id" @click="removeMeta(meta, index)" />
                                    <b-tooltip :target="'btn-remove-' + meta.id" :title="$t('views.seller.edit_product.text_1099')
                                        " />
                                </div>
                            </div>
                            <div>
                                <div class="pixel-type">
                                    <p>
                                        <b>{{
                                            $t("views.seller.edit_product.text_2255")
                                        }}:
                                        </b>
                                        {{ meta.key }}
                                    </p>
                                    <p v-if="meta.key === 'product_in_voxuy'" class="mt-2">
                                        <b>{{
                                            $t("views.seller.edit_product.text_2256")
                                        }}: </b>{{ meta.value | treatVoxuy }}
                                    </p>
                                    <p v-else class="mt-2">
                                        <b>{{
                                            $t("views.seller.edit_product.text_2256")
                                        }}: </b>{{ meta.value }}
                                    </p>
                                </div>
                                <p>{{ meta.updated_at | datetime }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p class="nao-encontrado">
                            {{ $t("views.seller.edit_product.text_1100") }}
                        </p>
                    </div>
                </b-col>
                <b-col class="mt-3 container-pixels" v-if="loading_meta">
                    <b-spinner label="Loading..."></b-spinner>
                </b-col>
            </div>

            <!-- Pixel -->
            <div class="mt-4 mt-md-0 col-12 col-md-6">
                <b-col class="container-pixels" v-if="!loading_pixel">
                    <div class="d-flex justify-content-between align-content-center mb-3">
                        <h5 class="m-0 h5-title">Pixel</h5>
                        <BaseButton variant="info2" id="pixels" class="btn-pixel" v-if="!novoPixel"
                            @click="novoPixel = true">{{
                                $t("views.seller.edit_product.text_1101")
                            }}</BaseButton>
                    </div>
                    <b-form data-anima="top" class="mb-5" v-if="novoPixel">
                        <b-col cols="12">
                            <b-form-group :label="$t('views.seller.edit_product.text_2260')" label-for="type_pixel">
                                <b-form-select id="type_pixel" name="type_pixel" v-model="itemPixels.type"
                                    @change="changePixelType" :placeholder="$t('views.seller.edit_product.text_2261')
                                        " :options="typesPixels" :class="{ heaven: isHeaven }"></b-form-select>
                            </b-form-group>
                        </b-col>

                        <div class="d-flex justify-content-end">
                            <BaseButton variant="link-info" id="pixels" class="btn-pixel" @click="cancelPixel">{{
                                $t("views.seller.edit_product.text_2257")
                            }}</BaseButton>
                        </div>
                    </b-form>
                    <div v-if="pixels.length">
                        <div v-for="(pixel, index) in pixels" :key="pixel.id" class="pixel">
                            <div>
                                <img class="iconePixel" :src="iconPixel(pixel.type)" :alt="pixel.type" />
                                <div>
                                    <div v-if="pixel.type == 'TIKTOK'" class="mr-2">
                                        <a href="#" @click.prevent="openTestTikTok" class="teste-tiktok">{{
                                            $t("views.seller.edit_product.text_1102")
                                        }}</a>
                                    </div>
                                    <img src="@/assets/img/icons/editar.svg" class="icon-lixeira lixeira-pixel mr-2"
                                        :id="'btn-edit-' + pixel.id" @click="editPixelFb(pixel)" />
                                    <b-tooltip :target="'btn-edit-' + pixel.id" :title="$t('views.seller.edit_product.text_2259')
                                        " placement="left" />
                                    <img src="@/assets/img/icons/lixeira.svg" class="icon-lixeira lixeira-pixel"
                                        :target="'btn-remove-' + pixel.id" @click="
                                            removePixels(
                                                index,
                                                pixel.pixel_id,
                                                pixel.type,
                                                pixel.id
                                            )
                                            " />
                                    <b-tooltip :target="'btn-remove-' + pixel.id" :title="$t('views.seller.edit_product.text_1099')
                                        " />
                                </div>
                            </div>
                            <div>
                                <div class="pixel-type">
                                    <p><b>ID Pixel: </b> {{ pixel.pixel_id }}</p>
                                    <p v-if="pixel.label">
                                        <b>Label: </b> {{ pixel.label }}
                                    </p>
                                    <p class="mt-2" v-if="pixel.domain">
                                        <b>{{
                                            $t("views.seller.edit_product.text_2262")
                                        }}: </b>{{ pixel.domain.domain }}
                                    </p>
                                </div>
                                <p>{{ pixel.updated_at | datetime }}</p>
                            </div>
                            <div class="pixel-status mt-3">
                                <span id="cname" class="status-pixel" :class="{
                                    heaven: isHeaven,
                                    ativada: pixels[index].domain ? pixels[index].domain.dns : false,
                                }">
                                    CNAME
                                    {{
                                        pixels[index].domain && pixels[index].domain.dns
                                            ? $t("views.seller.edit_product.text_2263")
                                            : $t("views.seller.edit_product.text_2264")
                                    }}
                                </span>
                                <b-tooltip v-if="!pixels[index].domain || (pixels[index].domain && !pixels[index].domain.ssl)" target="cname" :title="$t('views.seller.edit_product.text_1103')" />
                                <span id="ssl" class="status-pixel" :class="{
                                    heaven: isHeaven,
                                    ativada: pixels[index].domain ? pixels[index].domain.ssl : false,
                                }">
                                    {{ $t("views.seller.edit_product.text_2265") }}
                                    {{
                                        pixels[index].domain && pixels[index].domain.ssl
                                        ? $t("views.seller.edit_product.text_2263")
                                        : $t("views.seller.edit_product.text_2264")
                                    }}
                                </span>
                                <b-tooltip v-if="!pixels[index].domain || (pixels[index].domain && !pixels[index].domain.ssl)" target="ssl" :title="$t('views.seller.edit_product.text_1104')" />
                                <span id="otimizacao" class="status-pixel" :class="{
                                    heaven: isHeaven,
                                    ativada: pixel.optimization,
                                }">
                                    {{ $t("views.seller.edit_product.text_2266") }}
                                    {{
                                        pixel.optimization
                                        ? $t("views.seller.edit_product.text_2267")
                                        : $t("views.seller.edit_product.text_2268")
                                    }}
                                </span>
                                <b-tooltip v-if="!pixel.optimization" target="otimizacao" :title="`${$t(
                                    'views.seller.edit_product.text_1105'
                                )} ${pixel.optimization
                                    ? $t('views.seller.edit_product.text_2267')
                                    : $t('views.seller.edit_product.text_2268')
                                    }`" />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p class="nao-encontrado">
                            {{ $t("views.seller.edit_product.text_1106") }}
                        </p>
                    </div>
                </b-col>
                <b-col class="mt-3 container-pixels" v-if="loading_pixel">
                    <b-spinner label="Loading..."></b-spinner>
                </b-col>
            </div>
        </b-row>

        <PixelFb @close="atualizaListaPixel" :product_id="productId" :pixel_edit="pixel_edit" :type_pixel="pixel_type" />
    </section>
</template>
<script>

// Core
import Vue from "vue";
import { mapGetters, mapMutations  } from "vuex";

// Components
import CustomHeader from "../shared/CustomHeader.vue";
import FormItem from "../shared/FormItem.vue";

// Modals
import PixelFb from "@/components/Seller/Products/modals/PixelFb";

// Services
import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import DomainPixelService from "@/services/resources/DomainPixelService";
const serviceDomain = DomainPixelService.build();

import ConversionPixelService from "@/services/resources/ConversionPixelService";
const serviceConversionPixel = ConversionPixelService.build();

import GetPixelService from "@/services/resources/GetPixelService";
const serviceGetPixel = GetPixelService.build();

// Mixins
import Money from "@/mixins/money";

export default {
    components: {
        CustomHeader,
        FormItem,
        PixelFb
    },
    computed: {
        ...mapGetters([
            "getProfile",
            "product",
            "productId",
            "isLoading"
        ]),
        isHeaven() {
            return this.$store.getters["getIsHeaven"];
        },
        baseURL() {
            return this.isHeaven
                ? process.env.VUE_SALES_HEAVEN_PAGE
                : process.env.VUE_SALES_FAST_PAGE;
        },
    },
    data() {
        return {
            //pixel
            pixels: [],
            product_local: null,
            loading_pixel: false,
            novoPixel: false,
            pixel_edit: {
                item: {},
                edit: false,
            },
            pixel_type: "",
            itemPixels: {
                pixel_id: "",
                type: "",
            },
            typesPixels: [
                {
                    value: null,
                    text: this.$t("views.seller.edit_product.text_2277"),
                    disabled: true,
                },
                { value: "FACEBOOK", text: "Facebook" },
                { value: "GOOGLEADWORDS", text: "Google AdWords" },
                { value: "GOOGLETAGMANAGER", text: "Google Tag Manager" },
                { value: "GOOGLEANALYTICS", text: "Google Analytics" },
                { value: "GA4", text: "Google Analytics 4 (GA4)" },
                { value: "TIKTOK", text: "TikTok" },
            ],

            //metas
            loading_meta: false,
            novaMeta: false,
            metas: [],
            newMeta: {
                key: null,
                value: null,
            },
        }
    },
    methods: {
        ...mapMutations([
            'setPixels',
        ]),
        openModal(modal) {
            this.$bvModal.show(modal);
        },
        iconPixel(type) {
            if (type) {
                return require(`@/assets/img/${type.toLowerCase()}_icone.png`);
            } else return false;
        },
        // metas
        fetchMetas() {
            if (!this.metas.length) {
                this.loading_meta = true;
                let data = {
                    id: `${this.productId}/meta`,
                };

                this.metas = [];

                serviceProduct
                    .read(data)
                    .then((response) => {
                        if (response.length) {
                            this.metas = response.reverse();
                        } else {
                            this.metas = response;
                        }
                    })
                    .finally(() => {
                        this.loading_meta = false;
                    });
            }
        },
        editMeta(meta) {
            this.novaMeta = true;
            this.newMeta = meta;
        },
        removeMeta(meta, index_lista) {
            Vue.swal({
                title: this.$t("views.seller.edit_product.text_2354"),
                text: `${this.$t("views.seller.edit_product.text_2355")} ${meta.key
                    } | ${meta.value}?`,
                type: "danger",
                showCancelButton: true,
                confirmButtonText: this.$t("views.seller.edit_product.text_2250"),
                cancelButtonText: this.$t("views.seller.edit_product.text_2257"),
                customClass: "sweet-container",
                confirmButtonClass: "button button-danger mt-3 mb-3",
                cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
                buttonsStyling: false,
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let data = {
                        id: `${this.productId}/meta/${meta.id}`,
                    };

                    serviceProduct
                        .destroy(data)
                        .then((response) => {
                            if (response.success) {
                                // atualizando front
                                this.metas.splice(index_lista, 1);
                                this.$bvToast.toast(
                                    this.$t("views.seller.edit_product.text_2356"),
                                    {
                                        title: "Meta",
                                        variant: "success",
                                        autoHideDelay: 5000,
                                        appendToast: true,
                                    }
                                );
                            } else {
                                this.$bvToast.toast(
                                    this.$t("views.seller.edit_product.text_2357"),
                                    {
                                        title: "Meta",
                                        variant: "danger",
                                        autoHideDelay: 5000,
                                        appendToast: true,
                                    }
                                );
                            }
                        })
                        .finally(() => {
                            this.loading_meta = false;
                        });
                }
            });
        },
        addMeta() {
            this.loading_meta = true;

            if (!this.newMeta.id) {
                serviceProduct
                    .create(this.newMeta, `${this.productId}/meta`)
                    .then((response) => {
                        this.metas.unshift(response);
                        this.cancelMeta();
                        this.$bvToast.toast(
                            this.$t("views.seller.edit_product.text_2358"),
                            {
                                title: "Meta",
                                variant: "success",
                                autoHideDelay: 5000,
                                appendToast: true,
                            }
                        );
                    })
                    .finally(() => {
                        this.loading_meta = false;
                    });
            } else {
                serviceProduct
                    .update(this.newMeta, `${this.productId}/meta/${this.newMeta.id}`)
                    .then(() => {
                        this.cancelMeta();
                        this.fetchMetas();
                        this.$bvToast.toast(
                            this.$t("views.seller.edit_product.text_2359"),
                            {
                                title: "Meta",
                                variant: "success",
                                autoHideDelay: 5000,
                                appendToast: true,
                            }
                        );
                    })
                    .finally(() => {
                        this.loading_meta = false;
                    });
            }
        },

        cancelMeta() {
            this.novaMeta = false;
            this.newMeta = {
                key: null,
                value: null,
            };
        },

        openTestTikTok() {
            window.open(`${this.baseURL}/${this.productId}/?debugPixel=true`);
        },
        atualizaListaPixel(edit) {
            this.itemPixels = {
                pixel_id: "",
                type: null,
            };
            if (edit) {
                this.pixels = [];
                this.fetchPixels();
            }
        },
        // pixels
        fetchPixels() {
            if (!this.pixels.length) {
                let data = {
                    id: this.productId,
                };
                serviceGetPixel.read(data).then((response) => {
                    if (response.length) {
                        this.pixels = response.reverse().map((item) => ({
                            ...item,
                            ssl: 0,
                            dns: 0,
                        }));
                        if(!this.local_product) this.local_product = { pixels: [] }
                        this.local_product.pixels = this.pixels;
                        this.$store.commit('setPixels', this.Pixels);

                        this.pixel_edit = {
                            item: {},
                            edit: false,
                        };
                        this.pixels.forEach((item, index) => {
                            this.validaDomainPixel(item, index);
                        });
                    } else {
                        this.pixels = [];
                        if(!this.local_product) this.local_product = { pixels: [] }
                        this.local_product.pixels = [];
                        this.loading_pixel = false;
                        this.$store.commit('setPixels', []);
                    }
                });
            }
        },
        validaDomainPixel(pixel, index) {
            this.loading_pixel = true;

            let data = {
                domain: pixel.domain ? pixel.domain.domain : "",
            };

            if (data.domain) {
                serviceDomain
                    .search(data)
                    .then((response) => {
                        pixel.dns = response.dns;
                        pixel.ssl = response.ssl;
                    })
                    .finally(() => {
                        this.loading_pixel = false;
                    });
            } else {
                this.loading_pixel = false;
            }
        },
        changePixelType(e) {
            this.novoPixel = false;
            this.pixel_edit = {
                item: {},
                edit: false,
            };
            this.pixel_type = e;
            this.openModal("pixel-fb");
            this.itemPixels.type = null;
        },
        cancelPixel() {
            this.novoPixel = false;
            this.itemPixels = {
                pixel_id: "",
                type: null,
            };
        },
        editPixelFb(pixel) {
            this.pixel_type = pixel.type;
            this.pixel_edit = {
                item: pixel,
                edit: true,
            };
            this.openModal("pixel-fb");
        },
        removePixelItem(id_pixel) {
            this.loading_pixel = true;
            let data = {
                id: id_pixel,
            };
            serviceConversionPixel
                .destroy(data)
                .then(() => {
                    this.$bvToast.toast(this.$t("views.seller.edit_product.text_2360"), {
                        title: "Pixel",
                        variant: "success",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                })
                .finally(() => {
                    this.loading_pixel = false;
                });
        },

        removePixels(index_lista, pixel_id, type, id_pixel) {
            Vue.swal({
                title: this.$t("views.seller.edit_product.text_2361"),
                text: `${this.$t("views.seller.edit_product.text_2362")} ${pixel_id}?`,
                type: "danger",
                showCancelButton: true,
                confirmButtonText: this.$t("views.seller.edit_product.text_2250"),
                cancelButtonText: this.$t("views.seller.edit_product.text_2257"),
                customClass: "sweet-container",
                confirmButtonClass: "button button-danger mt-3 mb-3",
                cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
                buttonsStyling: false,
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    // atualizando front
                    this.pixels.splice(index_lista, 1);
                    this.removePixelItem(id_pixel);
                }
            });
        },
    },
    mounted: function () {
        this.fetchPixels();
        this.fetchMetas();
    },
    mixins: [Money],
};
</script>